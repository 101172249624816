import * as Yup from 'yup'
import {basicNameSchema, patronymicOptionalSchema, phoneNumberSchema} from '../../../../../../_metronic/helpers/custom/yup/partials/generalPartials'

const profileOverviewSchema = (intl: any, regex:string|undefined) =>
  Yup.object().shape({
    firstName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_NAME, regex),
    lastName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_LAST_NAME, regex),
    patronymic: patronymicOptionalSchema(intl, regex, 'noPatronymic'),
    passport: Yup.mixed(),
  })

const profileContactsSchema = (intl: any) =>
  Yup.object().shape({
    phoneNumber: phoneNumberSchema(intl),
    contactPhoneNumbers: Yup.array().of(phoneNumberSchema(intl, false)),
    email: Yup.string(),
  })

const profileSettingsSchema = (intl: any) =>
  Yup.object({
    delivery_type: Yup.number().required().label(intl.messages.YUP_VALIDATION_DEL_SETTINGS_TYPE),
    uast_force_dispatch: Yup.number()
      .required()
      .label(intl.messages.YUP_VALIDATION_DEL_SETTINGS_TYPE),
    dispatch: Yup.number().required().label(intl.messages.YUP_VALIDATION_DEL_SETTINGS_TRACK),
    dispatch_order: Yup.number().required().label(intl.messages.YUP_VALIDATION_DEL_SETTINGS_BUYOUT),
    notification_email: Yup.boolean(),
    notification_bm: Yup.lazy((value, context) => {
      const { notification_email } = context.parent;
      return Yup.boolean().test(
        'at-least-one',
        intl.messages.YUP_VALIDATION_NOTIFICATIONS_REQUIRED,
        () => notification_email || value
      );
    })
  })

export {profileOverviewSchema, profileContactsSchema, profileSettingsSchema}
