import {useEffect, useRef, useState} from 'react'
import {ErrorMessage, Form, Formik, FormikValues} from 'formik'
import {NewReceiverFullForm} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {addReceiverAddress, createReceiver} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {addReceiverSchema} from '../core/yup/receiver-page'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ReceiverTypeCheckbox} from '../../elements/ReceiverTypeCheckbox'
import {receiversApi} from '../../../../../_metronic/helpers/custom/api/receiversApi'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {AddressShipment} from '../../../../modules/custom/sections/shipment/_modals/address-shipment/AddressShipment'
import {PostShipment} from '../../../../modules/custom/sections/shipment/_modals/post-shipment/PostShipment'
import {handleFileUpload} from '../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {useMutation} from 'react-query'
import {CreateError} from '../../../../modules/custom/responseHandlers/CreateError'
import {PatronymicCheckbox} from '../../elements/PatronymicCheckbox'
import {ReceiverContact} from '../../elements/ReceiverContact'
import {FormattedMessage, useIntl} from 'react-intl'
import {shapeAddressData} from '../../../../../_metronic/helpers/custom/funcs/shapeAddressData'
import {FileInput} from '../../../../modules/custom/form-elements/file-inputs/FileInput'
import {useLayout} from "../../../../../_metronic/layout/core";

const AddReceiverModalForm = () => {
  const intl = useIntl()
  const {websiteData} = useLayout()
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const [currPassportScan, setCurrPassportScan] = useState({base_url: '', filename: ''})
  const [createResponse, setCreateResponse] = useState('')
  // @ts-ignore
  const [submittedValues, setSubmittedValues] = useState<any>(null)
  const [addressValues, setAddressValues] = useState<any>(null)

  const [submittedForm, setSubmittedForm] = useState(false)
  const [newReceiverId, setNewReceiverId] = useState('')
  const [imageIsLoading, setImageIsLoading] = useState(false)

  const inits: NewReceiverFullForm = {
    firstName: '',
    lastName: '',
    patronymic: '',
    premisesType: 'apartment',
    noPatronymic: false,
    passport: currPassportScan,
    receiverType: [1],
    typeOfDelivery: 1,
    city: '',
    phoneNumber: '',
  }

  const filterValues = (accepted: string[], values: any) => {
    const result = {}
    //@ts-ignore
    for (const type in values) if (accepted.indexOf(type) > -1) result[type] = values[type]

    return result
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    // setItemIdForUpdate(undefined)
  }

  const createReceiverMutation = useMutation(createReceiver, {
    onMutate: () => {
      setCreateResponse('loading')
    },
    onSuccess: (data) => {
      setCreateResponse('success')
      setNewReceiverId(data.id || '')
    },

    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      cancel(true)
    },
  })

  const addReceiverAddressMutation = useMutation(addReceiverAddress, {
    onSuccess: () => {
      setCreateResponse('success')
      setItemIdForUpdate(undefined)
      refetch()
    },
    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      cancel(true)
      setLoading(false)
    },
  })

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setLoading(true)
    setImageIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'passport',
      'file',
      receiversApi.addPassportScan
    )

    if (linkResponse) {
      setCurrPassportScan(linkResponse)
    } else {
      // set error
      console.log('ошибка')
    }
    setImageIsLoading(false)
    setLoading(false)
  }

  const deleteImage = () => {
    setCurrPassportScan({base_url: '', filename: ''})
  }

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [currentSchema, setCurrentSchema] = useState(addReceiverSchema(intl, websiteData.userNameScript)[0])
  const [initValues] = useState<NewReceiverFullForm>(inits)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(addReceiverSchema(intl, websiteData.userNameScript)[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: NewReceiverFullForm, actions: FormikValues) => {
    console.log('values', values)

    if (!stepper.current) {
      return
    }

    setCurrentSchema(addReceiverSchema(intl, websiteData.userNameScript)[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber) {
      stepper.current?.goNext()
      const {
        apartment,
        office,
        premisesType,
        city,
        email,
        firstName,
        houseNumber,
        lastName,
        passport,
        patronymic,
        phoneNumber,
        receiverType,
        street,
        typeOfDelivery,
        mainOfficeCity,
        mainOfficeNumber,
        reserveOfficeCity,
        reserveOfficeNumber,
        noPatronymic,
      } = values
      const toSend = {
        apartment,
        office,
        premisesType,
        city,
        email,
        firstName: firstName?.trim(),
        houseNumber,
        lastName: lastName?.trim(),
        no_pat: noPatronymic === true ? 1 : 0,
        passport: currPassportScan.filename,
        patronymic: patronymic?.trim(),
        phoneNumber,
        receiverType,
        street,
        typeOfDelivery,
        international: receiverType.includes(1) ? 1 : 0,
        receiver: receiverType.includes(2) ? 1 : 0,
        mainOfficeCity,
        mainOfficeNumber,
        reserveOfficeCity,
        reserveOfficeNumber,
        mainAddress: true,
      }

      setSubmittedValues(toSend)
      setSubmittedForm(true)

      stepper.current?.goNext()
    } else if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
      setCurrPassportScan({base_url: '', filename: ''})
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    if (submittedForm && submittedValues) {
      setLoading(true)
      createReceiverMutation.mutate(submittedValues)

      shapeAddressData(submittedValues, true).then((addressToSend) =>
        setAddressValues(addressToSend)
      )
    }
  }, [submittedValues])

  useEffect(() => {
    if (submittedForm && addressValues) {
      addReceiverAddressMutation.mutate({...addressValues, receiver_id: newReceiverId})
    }
  }, [addressValues, newReceiverId])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_modal_create_receiver_stepper'
    >
      <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
        <div className='stepper-nav ps-lg-10'>
          <div className='stepper-item current CustomStepper' data-kt-stepper-element='nav'>
            <div className='stepper-wrapper'>
              {' '}
              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>1</span>
              </div>
              <div className='stepper-label'>
                <h3 className='stepper-title'>
                  <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.PROFILE' />
                </h3>

                <div className='stepper-desc'>
                  <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.PERS_DATA' />
                </div>
              </div>
            </div>
          </div>
          <div className='stepper-item CustomStepper' data-kt-stepper-element='nav'>
            <div className='stepper-line h-40px'></div>

            <div className='stepper-wrapper'>
              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>2</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>
                  <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.DELIVERY' />
                </h3>

                <div className='stepper-desc'>
                  <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.CONTACTS_ADDRESS' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-row-fluid py-lg-5 px-lg-15'>
        <Formik
          validationSchema={currentSchema}
          initialValues={initValues}
          onSubmit={submitStep}
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form'>
              {/*    {JSON.stringify(errors)}
                {JSON.stringify(values)} */}
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row mb-10 d-flex flex-md-gutter flex-wrap flex-md-nowrap'>
                    <InputTemplate
                      inputName='lastName'
                      type='text'
                      required={true}
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.LAST_NAME'})}
                      addSymbol={
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-3'
                        />
                      }
                    />
                    <InputTemplate
                      inputName='firstName'
                      type='text'
                      required={true}
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.NAME'})}
                      addSymbol={
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-3'
                        />
                      }
                    />
                    <InputTemplate
                      inputName='patronymic'
                      type='text'
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.PATRONYMIC'})}
                      required={!values.noPatronymic}
                      addSymbol={
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-3'
                        />
                      }
                    />
                  </div>
                  <div className='fv-row mb-10'>
                    <PatronymicCheckbox
                      setFieldValue={setFieldValue}
                      title={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.NO_PATRONYMIC_CHECKBOX',
                      })}
                      value={values.noPatronymic}
                    />
                  </div>

                  <div className='fv-row mb-10'>
                    {' '}
                    <FileInput
                      inputId='add-receiver-form'
                      inputName='passport'
                      title={
                        intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE'}) +
                        ':'
                      }
                      uploadedFile={{
                        base: currPassportScan.base_url,
                        link: currPassportScan.filename,
                      }}
                      setFieldValue={setFieldValue}
                      uploadFunction={saveImage}
                      simpleDeleteFunction={deleteImage}
                      placeholder={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                      })}
                      notification={{
                        bool: true,
                        text: intl.formatMessage({
                          id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE.PROMPT',
                        }),
                        borderTop: false,
                      }}
                      loading={imageIsLoading}
                      linkEndpoint='/passport'
                    />
                  </div>
                  <div className='fv-row'>
                    <div className='row mb-2'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6 required p-0'>
                        <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE' />
                      </label>
                      <div className='col-lg-8'>
                        <div className='row '>
                          {[
                            {
                              val: 1,
                              label: intl.formatMessage({
                                id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER',
                              }),
                              noteText: intl.formatMessage({
                                id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER.DESC',
                              }),
                            },
                            {
                              val: 2,
                              label: intl.formatMessage({
                                id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL',
                              }),
                              noteText: intl.formatMessage({
                                id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL.DESC',
                              }),
                            },
                          ].map(({val, label, noteText}) => (
                            <ReceiverTypeCheckbox
                              key={val}
                              chosenTypes={values.receiverType}
                              setFieldValue={setFieldValue}
                              title={label}
                              typeValue={val}
                              noteText={noteText}
                            />
                          ))}
                        </div>
                        <div className='text-danger'>
                          <ErrorMessage name='receiverType' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row d-flex flex-gutter'>
                    <InputTemplate
                      inputName='phoneNumber'
                      required={true}
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.PHONE_LABEL'})}
                      type='tel'
                      fieldWidth='w-50'
                      setFieldValue={setFieldValue}
                      value={values.phoneNumber}
                      custom={
                        <ReceiverContact
                          inputName='phoneNumber'
                          setFieldValue={setFieldValue}
                          value={values.phoneNumber || ''}
                          setDisableSubmit={setDisableSubmit}
                        />
                      }
                    />

                    {/* {values.receiverType.includes(2) && ( */}
                    <InputTemplate
                      inputName='email'
                      type='mail'
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.EMAIL_LABEL'})}
                      fieldWidth='w-50'
                    />
                    {/* )} */}
                  </div>
                  <div className='fv-row'>
                    {values.receiverType.includes(2) ? (
                      <>
                        <div className='fv-row mb-10'>
                          <p className='required fs-5 fw-bold mb-7'>
                            <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.DEL_TYPE_LABEL' />
                          </p>
                          <InputTemplate
                            inputName='typeOfDelivery'
                            required={false}
                            type='radio'
                            value={1}
                            title={intl.formatMessage({
                              id: 'RECEIVERS.ADD_REC_MODAL.ADDRESS_DELIVERY',
                            })}
                            checkboxProps={{
                              checked: values.typeOfDelivery === 1 ? true : false,
                              disabled: false,
                              setFieldValue: setFieldValue,
                              value: 1,
                              inputName: 'typeOfDelivery',
                              type: 'radio',
                            }}
                          />
                          <InputTemplate
                            inputName='typeOfDelivery'
                            required={false}
                            type='radio'
                            value={2}
                            title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.NP_DELIVERY'})}
                            checkboxProps={{
                              checked: values.typeOfDelivery === 2 ? true : false,
                              disabled: false,
                              setFieldValue: setFieldValue,
                              value: 2,
                              inputName: 'typeOfDelivery',
                              type: 'radio',
                            }}
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='typeOfDelivery' />
                          </div>
                        </div>
                        <div>
                          {+values.typeOfDelivery === 1 && (
                            <AddressShipment
                              setFieldValue={setFieldValue}
                              // @ts-ignore
                              values={filterValues(
                                [
                                  'apartment',
                                  'city',
                                  'houseLetter',
                                  'houseNumber',
                                  'mainAddress',
                                  'office',
                                  'premisesType',
                                  'street',
                                  'typeOfDelivery',
                                ],
                                values
                              )}
                              editing={false}
                              vector={true}
                            />
                          )}
                          {+values.typeOfDelivery === 2 && (
                            <PostShipment
                              // @ts-ignore

                              values={filterValues(
                                [
                                  'city',
                                  'mainAddress',
                                  'mainOfficeCity',
                                  'mainOfficeNumber',
                                  'reserveOfficeCity',
                                  'reserveOfficeNumber',
                                  'typeOfDelivery',
                                ],
                                values
                              )}
                              editing={false}
                              vector={true}
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      <AddressShipment
                        // @ts-ignore

                        values={filterValues(
                          [
                            'apartment',
                            'city',
                            'houseLetter',
                            'houseNumber',
                            'mainAddress',
                            'office',
                            'premisesType',
                            'street',
                            'typeOfDelivery',
                          ],
                          values
                        )}
                        editing={false}
                        setFieldValue={setFieldValue}
                        vector={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    disabled={loading}
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    <FormattedMessage id='GENERAL.BUTTONS.BACK' />
                  </button>
                </div>

                <div>
                  <button
                    type='submit'
                    disabled={loading === true || disableSubmit === true}
                    className='btn btn-lg btn-primary me-3'
                  >
                    <span className='indicator-label'>
                      {loading === true ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <FormattedMessage id='GENERAL.BUTTONS.WAIT' />

                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          {' '}
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! && (
                            <FormattedMessage id='GENERAL.BUTTONS.NEXT' />
                          )}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! && (
                            <FormattedMessage id='GENERAL.BUTTONS.CREATE' />
                          )}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </>
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {AddReceiverModalForm}
