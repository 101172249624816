/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {useI18n} from '../../../i18n/Metronici18n'
import {languages} from "../../../helpers/staticData";

const Languages: FC = () => {
  const {selectedLang: lang, locales, setLanguage} = useI18n()
  const availableLanguages = languages.filter((x) => locales.includes(x.lang))
  const currentLanguage = availableLanguages.find((x) => x.lang === lang)
  const handleClick = (lang: string) => {
    setLanguage(lang)
  }

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='click'
      data-kt-menu-placement='bottom'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          <FormattedMessage id='HEADER_USER_MENU.LANGUAGE' />

          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {availableLanguages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              handleClick(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
