/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../_metronic/helpers'
import {getConfig, useLang} from '../../../_metronic/i18n/Metronici18n'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {IDashboardItem} from './core/_models'
import {getDashboardInfo, loadData} from './core/_requests'
import {DashboardColumnWrapper} from './DashboardColumnWrapper'
import {dashboardData} from './data'

const DashboardPage = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [convertedData, setConvertedData] = useState<IDashboardItem[][]>([])

  useEffect(() => {
    loadData()
  }, [])

  const {
    data: savedData,
    isLoading,
    isError,
  }: any = useQuery(QUERIES.DASHBOARD_INFO, getDashboardInfo, {cacheTime: 0})

  const setFunc = async () => {
    if (savedData !== undefined) {
      const converted = dashboardData(savedData, currentUser, intl)
      setConvertedData(converted)
    }
  }

  useEffect(() => {
    setFunc()
  }, [savedData, intl])

  if (isLoading) {
    return <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
  }

  if (isError) {
    return <div>Error loading data</div>
  }

  return (
    <>
      {convertedData ? (
        <div className='row g-xl-10 mb-5 mb-xl-10 d-flex flex-wrap'>
          {convertedData.map((item: any, index: number) => (
            <DashboardColumnWrapper items={item} key={index} />
          ))}
        </div>
      ) : (
        <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
      )}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'DASHBOARD.PAGE.PAGE_TITLE'})}
      </PageTitle>

      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
