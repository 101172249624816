import {Link} from 'react-router-dom'
import {IDashboardItemRow} from './core/_models'

interface Props {
  index: number
  row: IDashboardItemRow
  rowsLength: number
  type?: string
}

export function DashboardItemStatBase({row, index, rowsLength, type}: Props) {
  const date = row?.date?.split(' ')[0].split('-').reverse().join('-')
  const finalDateAndTime = date ? date + ' ' + row?.date?.split(' ')[1] : ''
  return (
    <tr className={`${rowsLength - 1 > index ? 'border-bottom border-bottom-dashed my-3' : ''}`}>
      {/* <div className='d-flex flex-stack'> */}
      <td>
        <span className='text-primary fw-semibold fs-6 me-2'>{row?.title}</span>
      </td>
      <td>
        <div className='d-flex flex-column'>
          {typeof row?.stat === 'string' || typeof row?.stat === 'number' ? (
            <span className='btn btn-icon btn-sm h-auto btn-color-black-400'>{row?.stat || 0}</span>
          ) : (
            <Link to={row?.stat?.avia?.linkTo} state={{filters: row?.stat?.filters}}>
              <span className='btn btn-icon btn-sm h-auto btn-color-black-400'>
                {row?.stat?.avia?.number}
              </span>
            </Link>
          )}

          {type === 'balance' && (
            <span className='btn btn-icon btn-sm h-auto btn-color-gray-400 fs-9 w-auto fw-normal text-justify justify-content-end'>
              {finalDateAndTime}
            </span>
          )}
          {type === 'parcel' && row?.add && row?.stat > 0 ? (
            <span className='btn btn-icon btn-sm h-auto btn-color-gray-400 fs-9 w-auto fw-normal text-justify justify-content-end'>
              ${row?.add}
            </span>
          ) : (
            <></>
          )}
        </div>
      </td>
      {/* {row?.stat && typeof row?.stat !== 'string' && typeof row?.stat !== 'number' && (
        <td className='text-end'>
          {row && (
            <Link to={row?.stat?.sea?.linkTo} state={{filters: row?.stat?.filters}}>
              <span className='btn btn-icon btn-sm h-auto btn-color-black-400 justify-content-start text-start'>
                {row?.stat?.sea?.number}
              </span>
            </Link>
          )}
        </td>
      )} */}
      {/* </div> */}
    </tr>
  )
}
