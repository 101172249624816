/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {confirmEmailResend, crauzerRegister, getPhoneCodes, getUser} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {QUERIES, setYupLocale} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {FormattedMessage, useIntl} from 'react-intl'
import {emailRegex, passwordRegex} from '../../../../_metronic/helpers/custom/regexs'
import RegistrationConfirmOptions from './RegistrationConfirmOptions'
import {TermsOfServiceModal} from '../../../pages/profile/vector/components/overview/TermsOfServiceModal'
import {TelegramAuthModal} from "../../../pages/profile/vector/components/overview/TelegramAuthModal";
import {crauzerProfileApi} from '../../../pages/profile/_requests'
import passwordSymbols from '../../../../data/general/passwordSymbols.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import {useQuery} from "react-query";
import {PhoneInput} from "../../custom/form-elements/PhoneInput";
import {basicNameSchema} from "../../../../_metronic/helpers/custom/yup/partials/generalPartials";
import {useLayout} from "../../../../_metronic/layout/core";

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneNumber_valid: false,
  password: '',
  confirmPassword: '',
  acceptTerms: false,
}

export function Registration() {
  const intl = useIntl()
  let navigate = useNavigate()
  setYupLocale(intl)
  const {websiteData} = useLayout()
  const registrationSchema = Yup.object().shape(
    {
      email: Yup.string().required().email().min(5).max(50).matches(emailRegex),
      phoneNumber: Yup.string().required().min(10).test(
        'is-valid-phone',
        intl.formatMessage({id: 'GENERAL.ERRORS.WRONG_PHONE_NUMBER'}),
        function (value) {
          return this.parent.phoneNumber_valid === true;
        }
      ),
      password: Yup.string()
        .min(8)
        .max(20)
        .matches(passwordRegex, intl.formatMessage({id: 'VALIDATION.INVALID'}))
        .required(),
      confirmPassword: Yup.string()
        .required()
        .when('password', {
          is: (val: string) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            intl.formatMessage({id: 'VALIDATION.PASSWORDS'})
          ),
        }),
      firstName: basicNameSchema(intl, intl.formatMessage({id: 'YUP_VALIDATION_GENERAL_ENTER_NAME'}), websiteData.userNameScript),
      lastName: basicNameSchema(intl, intl.formatMessage({id: 'YUP_VALIDATION_GENERAL_ENTER_LAST_NAME'}), websiteData.userNameScript),
      acceptTerms: Yup.bool().required(intl.formatMessage({id: 'VALIDATION.TERMS'})),
    },
    // @ts-ignore
    ['email', 'phoneNumber', 'password', 'confirmPassword', 'firstName', 'lastName']
  )
  const [loading, setLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [tosOpen, setTosOpen] = useState(false)
  const [telegramOpen, setTelegramOpen] = useState(false)

  const [passwordFieldType, setPasswordFieldType] = useState('password')

  useQuery(QUERIES.PHONE_CODES_LIST, getPhoneCodes)

  const resendEmail = async (email: string) => {
    try {
      await confirmEmailResend(email)
      return navigate('/auth/registration/done', {state: {email}})
    } catch (error) {
      console.log('resend err', error)
    }
  }

  const try_token_auth = async () => {
    const {data: user} = await getUser()
    const crUser = await crauzerProfileApi.getCurrentUser()
    setCurrentUser({...user, ...crUser})
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting, setFieldValue, setFieldError}) => {
      setLoading(true)
      setStatus(null)
      try {
        let token = ''

        const {data: auth} = await crauzerRegister(
          values.email,
          values.firstName,
          values.lastName,
          values.password,
          values.phoneNumber,
          intl.locale
        )

        console.log(auth)
        token = auth.token

        if (token) {
          saveAuth({token: token})
          try_token_auth()
        }
        setShowConfirm(true)
        setFieldValue('confToken', auth.data.telegram_check_token)
      } catch (error: any) {
        console.error(error)
        saveAuth(undefined)
        let errMsg = intl.formatMessage({id: 'ERROR'})
        let ecount = 0
        if (error.response.data?.errors) {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
            // @ts-ignore
            if (value[0] === 'AUTH.REGISTRATION.EMAIL_CONFIRMATION_REQUIRED') {
              setStatus({show: false, text: 'show-resend'})
            }
            console.log('val', value)

            if (key in values) {
              // @ts-ignore
              setFieldError(key, intl.formatMessage({id: value, defaultMessage: value}))
              if(key === 'phoneNumber' && value === "AUTH.VALIDATION.PHONE_NUMBER_TAKEN_BY_TELEGRAM_CHAT_USER")
                setTelegramOpen(true)
              ecount++
            } else {
              if (value && ecount === 0)
                // @ts-ignore
                errMsg = intl.formatMessage({id: value, defaultMessage: value})
            }
          }
        }
        if (ecount === 0) setStatus(errMsg)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const generatePassword = () => {
    let string = ''

    for (let i = 0; i < 12; i++) {
      const everyNSymbolToUpperCase = Math.floor(Math.random() * 11)
      const symbol = passwordSymbols[Math.floor(Math.random() * passwordSymbols.length)]
      string +=
        i === everyNSymbolToUpperCase && !!symbol.toString().toUpperCase()
          ? symbol.toString().toUpperCase()
          : symbol
    }

    formik.setFieldValue('password', string)
    formik.setFieldValue('confirmPassword', string)

    console.log(string)
  }

  return (
    <>
      <TermsOfServiceModal registerProps={{isOpen: tosOpen, setIsOpen: setTosOpen}} />
      <TelegramAuthModal registerProps={{isOpen: telegramOpen, setIsOpen: setTelegramOpen}} />
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3'>
          <FormattedMessage id='AUTH.REGISTER.TITLE' />
        </h1>

        <div className='text-gray-400 fw-bold fs-4'>
          <FormattedMessage id='AUTH.REGISTER.ALREADY' />
          <Link
            to='/auth/login'
            state={{email: formik.values.email}}
            className='link-primary fw-bolder'
            style={{marginLeft: '5px'}}
          >
            <FormattedMessage id='AUTH.LOGIN.BUTTON' />
          </Link>
        </div>
      </div>{' '}
      {!showConfirm ? (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {formik.status && formik.status?.show !== false && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Email <span className='text-danger'></span>
            </label>
            <input
              type='email'
              autoComplete='off'
              // placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
                {formik.status?.text && formik.status?.text === 'show-resend' && (
                  <div className='d-flex justify-content-center mt-2'>
                    <a
                      className='btn text-primary flex-center btn-light btn-secondary btn-sm'
                      href='#'
                      onClick={() => resendEmail(formik.values.email)}
                    >
                      {intl.formatMessage({id: 'AUTH.EMAIL_RESEND'})}
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              <FormattedMessage id='AUTH.INPUT.PHONE' /> <span className='text-danger'></span>
            </label>

            <PhoneInput
              value={formik.values.phoneNumber}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phoneNumber}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-7 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6 required'>
                <FormattedMessage id='AUTH.INPUT.PASSWORD' /> <span className='text-danger'></span>
              </label>
              <div className='position-relative mb-3'>
                <input
                  type={passwordFieldType}
                  // placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                  autoComplete='new-password'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <span
                  style={{top: 15, right: 25}}
                  className='position-absolute'
                  onClick={() =>
                    setPasswordFieldType(passwordFieldType === 'text' ? 'password' : 'text')
                  }
                >
                  {' '}
                  <FontAwesomeIcon
                    icon={passwordFieldType === 'text' ? faEyeSlash : faEye}
                    fontSize={16}
                    className='svg-icon-2 ms-2 me-4'
                  />
                </span>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
                <div className='mt-3'>
                  <a
                    href='#'
                    className='link text-primary text-decoration-underline p-1'
                    onClick={(e) => {
                      e.preventDefault()
                      generatePassword()
                    }}
                  >
                    <FormattedMessage id='AUTH.REGISTER.AUTO_PASSWORD' />
                  </a>
                </div>
              </div>
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px' />
              </div>
            </div>
            <div className='text-muted'>
              <FormattedMessage
                id='AUTH.INPUT.PASSWORD_COND'
                values={
                  // @ts-ignore
                  {b: (chunks: any) => <b>{chunks}</b>}
                }
              />
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              <FormattedMessage id='AUTH.INPUT.CONFIRM_PASSWORD' />{' '}
              <span className='text-danger'></span>
            </label>
            <input
              type='password'
              // placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                },
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row fv-row mb-7'>
            <div className='col-xl-6 mb-7'>
              <label className='form-label fw-bolder text-dark fs-6 required'>
                <FormattedMessage id='AUTH.INPUT.FIRSTNAME' />
              </label>
              <input
                placeholder={intl.formatMessage({id: 'AUTH.INPUT.FIRSTNAME'})}
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('firstName')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.firstName && formik.errors.firstName,
                  },
                  {
                    'is-valid': formik.touched.firstName && !formik.errors.firstName,
                  }
                )}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.firstName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xl-6'>
              <div className='fv-row'>
                <label className='form-label fw-bolder text-dark fs-6 required'>
                  <FormattedMessage id='AUTH.INPUT.LASTNAME' />
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'AUTH.INPUT.LASTNAME'})}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('lastName')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.lastName && formik.errors.lastName,
                    },
                    {
                      'is-valid': formik.touched.lastName && !formik.errors.lastName,
                    }
                  )}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.lastName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='fv-row mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
              />
              <label
                className='form-check-label fw-bold text-gray-700 fs-6'
                htmlFor='kt_login_toc_agree'
              >
                <FormattedMessage id='AUTH.INPUT.ACCEPT' />{' '}
                <button
                  type='button'
                  className='ms-1 btn btn-link link-primary'
                  onClick={() => setTosOpen(true)}
                >
                  <FormattedMessage id='AUTH.INPUT.TERMS' />
                </button>
                .
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && (
                <span className='indicator-label'>
                  <FormattedMessage id='AUTH.REGISTER.CREATE' />
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <FormattedMessage id='AUTH.GENERAL.WAIT' />{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                <FormattedMessage id='AUTH.GENERAL.CANCEL_BUTTON' />
              </button>
            </Link>
          </div>
        </form>
      ) : (
        <RegistrationConfirmOptions values={formik.values} />
      )}
    </>
  )
}
