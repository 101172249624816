import {useRef, useEffect, useState, useMemo} from 'react'
import {useIntl} from "react-intl";
import {useQueryClient} from "react-query";
import {KTSVG, QUERIES} from "../../../../_metronic/helpers";
import {AsYouType} from "libphonenumber-js/mobile";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {pickBy, isEmpty} from 'lodash'
import {isoToFlag} from "../../../../_metronic/helpers/staticData";

interface Props {
  inputName?: string
  value?: string
  setFieldValue: (inputName:string, value:any)=>void
  setFieldTouched?: (inputName:string)=>void
}

type CodeList = Record<string, {code: string, name: string}>
let rend = 0
export function PhoneInput({inputName = 'phoneNumber', value, setFieldValue, setFieldTouched}: Props) {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const phoneCodes: CodeList = useMemo(
    () => queryClient.getQueryData([QUERIES.PHONE_CODES_LIST]) || {},
    [queryClient.isFetching([QUERIES.PHONE_CODES_LIST])]
  );
  // @ts-ignore
  const [currentCountry, setCurrentCountry] = useState<CountryCode>('UA')
  const [codeFilter, setCodeFilter] = useState<string>('')
  const [availableCodes, setAvailableCodes] = useState<CodeList>(phoneCodes)
  const [currentPhone, setCurrentPhone] = useState<string>(value||'')
  const [formattedPhone, setFormattedPhone] = useState<string>('')
  const [asYouType, setAsYouType] = useState<AsYouType>(new AsYouType(currentCountry))
  const inputRef = useRef(null)

  const handlePhoneInput = (val:string) => {
    let phone = `+${asYouType.getCallingCode()}${val}`;
    setCurrentPhone(phone)
    setFieldValue(inputName, phone.replace(/[^0-9+]/g, ''))
  }
  const cutCountryCode = (formatted:string, aYT:AsYouType) => {
    const toRep = `+${aYT.getCallingCode()}`;
    for(let i=0;i<toRep.length;i++){
      formatted = formatted.trim();
      if(formatted.startsWith(toRep[i]))
        formatted = formatted.slice(1);
    }
    return formatted;
  }
  const changeCountry = (iso:string, code:string) => {
    let national = cutCountryCode(currentPhone, asYouType);
    setCurrentPhone(`${code}${national}`);
    // @ts-ignore
    setCurrentCountry(iso)
  }
  const triggerMenuFocus = () => {
    setTimeout(()=>{
      // @ts-ignore
      inputRef.current?.focus()
    }, 50)
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  useEffect(() => {
    if(!isEmpty(phoneCodes)){
      // @ts-ignore
      setCurrentCountry(Object.keys(phoneCodes)[0])
      setCurrentPhone(value||phoneCodes[Object.keys(phoneCodes)[0]].code)
    }
  }, [value, phoneCodes])
  useEffect(() => {
    setAvailableCodes(phoneCodes)
  }, [phoneCodes])
  useEffect(() => {
    setAvailableCodes(pickBy(phoneCodes, (value, key) =>
      (value.name.toLowerCase().includes(codeFilter.toLowerCase()) || value.code.includes(codeFilter))
    ))
  }, [codeFilter])
  useEffect(() => {
    const aYT = new AsYouType(currentCountry);
    let formatted = aYT.input(currentPhone||'');
    formatted = cutCountryCode(formatted, aYT);
    setFormattedPhone(formatted)
    setAsYouType(aYT)
    setFieldValue(`${inputName}_valid`, aYT.isValid())
  }, [currentPhone, currentCountry])

  return (
    <div className='d-flex justify-content-start'>
      <div className="input-group input-group-solid">
        <div className="input-group-prepend">
          <button
            type="button"
            className="btn btn-secondary dropdown-toggle"
            data-kt-menu-trigger='click'
            data-kt-menu-target={`#${inputName}_menu`}
            data-kt-menu-placement='bottom-start'
            onClick={()=>triggerMenuFocus()}
          >
            <span className='symbol symbol-20px me-4'>
              <img className='rounded-1' src={isoToFlag(currentCountry)} alt='metronic'/>
            </span>
            +{asYouType?.getCallingCode()}
          </button>
        </div>
        <input
          name={inputName}
          type="text"
          className={`form-control form-control-lg form-control-solid ${asYouType.isValid()?'is-valid':'is-invalid'}`}
          value={formattedPhone}
          onChange={(e) => {handlePhoneInput(e.target.value)}}
          onBlur={()=> setFieldTouched ? setFieldTouched(inputName) :{}}
        />
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px phone-drop'
        data-kt-menu='true'
        id={`${inputName}_menu`}
      >
        <div className='px-7 py-5'>
          <input
            ref={inputRef}
            type="text"
            className={`form-control form-control-lg form-control-solid`}
            placeholder={intl.formatMessage({id: 'GENERAL.TABLE_SEARCH_WINDOW'})}
            value={codeFilter}
            onChange={(e) => {
              setCodeFilter(e.target.value)
            }}
          />
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-0 py-5 code-list'>
          {availableCodes && Object.keys(availableCodes).map(item=> (
            <div className='menu-item px-3 my-0' key={item}>
              <a
                href='#'
                className={'menu-link px-3 py-2'}
                onClick={() => changeCountry(item, availableCodes[item].code)}
                data-kt-menu-dismiss='true'
              >
                <span className='menu-icon' data-kt-element='icon'>
                  <KTSVG path={isoToFlag(item)} className='svg-icon-3'/>
                </span>
                <span className='menu-title'>
                  <span className='w-50px px-2'>{availableCodes[item].code}</span>
                  {availableCodes[item].name}
                </span>
              </a>
            </div>
          ))}
        </div>
      </div>
      {' '}
    </div>
  )
}
