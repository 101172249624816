import * as Yup from 'yup'
import {cyrillicOnly, latinOnly} from '../../regexs'

export const object_shape_basic_select = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string(),
})

const phoneNumberBase = (intl: any) =>
  Yup.string()
    .min(13, intl.messages.YUP_VALIDATION_GENERAL_MISSING_NUMBERS)
    .max(13, intl.messages.YUP_VALIDATION_GENERAL_MISSING_NUMBERS)

export const phoneNumberSchema = (intl: any, required = true) =>
  required
    ? phoneNumberBase(intl).required(intl.messages.YUP_VALIDATION_GENERAL_ENTER_PHONE_NUMBER)
    : phoneNumberBase(intl)

export const nameScripts = {
  'cyrillic': {
    script: cyrillicOnly,
    err: 'YUP_VALIDATION_GENERAL_ENTER_CYRILLIC_NO_SYMBOLS',
  },
  'latin': {
    script: latinOnly,
    err: 'YUP_VALIDATION_GENERAL_ENTER_LATIN_NO_SYMBOLS',
  }
};

export const basicNameSchema = (intl: any, reqMsg: string, regex:string|undefined) => {
  // @ts-ignore
  const nameRegex = nameScripts[regex||'cyrillic'];
  return Yup.string()
    .min(3, intl.messages.YUP_VALIDATION_GENERAL_MIN_SYMBOLS)
    .max(50, intl.messages.YUP_VALIDATION_GENERAL_MAX_SYMBOLS)
    .required(reqMsg)
    .matches(nameRegex.script, intl.messages[nameRegex.err])
}

export const patronymicOptionalSchema = (intl: any, regex:string|undefined, optField:string='no_pat') => {
  // @ts-ignore
  const nameRegex = nameScripts[regex||'cyrillic'];
  return Yup.string()
    .matches(nameRegex.script, intl.messages[nameRegex.err])
    .when(optField, {
      is: false,
      then: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_PATRONYMIC, regex),
    })
}
