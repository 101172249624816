import {ProfileNumberItem} from './ProfileNumberItem'
import {FormattedMessage} from 'react-intl'
import {FieldArray, useFormikContext} from 'formik'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useLayout} from '../../../../../../_metronic/layout/core'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'

type TProps = {
  user: ICrauzerUser | undefined
}

export function ProfileContactNumbers({user}: TProps) {
  const {websiteData} = useLayout()

  const {values} = useFormikContext<Partial<ICrauzerUser>>()

  return (
    <div className='row mb-6'>
      <label className='col-lg-4 col-form-label fw-bold fs-6'>
        <FormattedMessage id='PROFILE.PAGE.CONTACTS.NUMBERS' />
      </label>
      <div className='col-lg-8 w-auto'>
        <div className='row'>
          <FieldArray
            name='contactPhoneNumbers'
            render={() => (
              <div className='d-flex flex-column flex-col-gutter p-0'>
                {values.contactPhoneNumbers?.map((item, index) => (
                  <div className='d-flex' key={index}>
                    <ProfileNumberItem
                      inputName={`contactPhoneNumbers.${index}`}
                      value={item || ''}
                    />

                    {!user?.phoneNumber &&
                      user?.phoneNumberConfirmed === false &&
                      user.contactPhoneNumbers.find((i) => i === item) && (
                        <OverlayTrigger
                          placement='left'
                          overlay={<Tooltip>Підтвердити через Телеграм-бот.</Tooltip>}
                        >
                          <a
                            target={'_blank'}
                            href={`https://t.me/${websiteData.telegramBotName}`}
                            rel='noreferrer noopener'
                            className='btn btn-icon btn-light btn-lg ms-2'
                          >
                            <KTSVG
                              path='/media/svg/brand-logos/telegram-app.svg'
                              className='svg-icon-1'
                            />
                          </a>
                        </OverlayTrigger>
                      )}
                  </div>
                ))}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}
