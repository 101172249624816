import * as Yup from 'yup'
import {
  basicNameSchema,
  patronymicOptionalSchema,
  phoneNumberSchema,
} from '../../../../../../../_metronic/helpers/custom/yup/partials/generalPartials'

import {emailSchema, passportSchema, receiverTypeSchema, recTypeOfDeliverySchema} from './partials'
import {
  apartmentSchema,
  citySchema,
  houseLetterSchema,
  houseNumberSchema,
  mainOfficeCitySchema,
  mainOfficeNumberSchema,
  officeSchema,
  premisesTypeSchema,
  reserveOfficeCitySchema,
  reserveOfficeNumberSchema,
  streetSchema,
} from '../../../../../../../_metronic/helpers/custom/yup/partials/addressesPartials'

const receiverOverviewSchema = (intl: any, regex: string|undefined) =>
  Yup.object().shape({
    active: Yup.number().required(intl.messages.YUP_VALIDATION_RECEIVER_PICK_STATUS),
    main: Yup.boolean().required(),
    firstName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_NAME, regex),
    lastName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_LAST_NAME, regex),
    patronymic: patronymicOptionalSchema(intl, regex),
    document: passportSchema(intl),
    receiverType: receiverTypeSchema(intl),
  })

const receiverContactsSchema = (intl: any) =>
  Yup.object().shape({
    phoneNumber: phoneNumberSchema(intl),
    email: emailSchema,
  })

const addReceiverSchema = (intl: any, regex: string|undefined) => [
  Yup.object().shape({
    firstName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_NAME, regex),
    lastName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_LAST_NAME, regex),
    patronymic: patronymicOptionalSchema(intl, regex),
    document: passportSchema(intl),
    receiverType: receiverTypeSchema(intl),
  }),
  Yup.object().shape({
    phoneNumber: phoneNumberSchema(intl),
    email: emailSchema,
    typeOfDelivery: recTypeOfDeliverySchema(intl),
    houseNumber: houseNumberSchema(intl),
    houseLetter: houseLetterSchema(intl),
    street: streetSchema(intl),
    premisesType: premisesTypeSchema(intl),
    apartment: apartmentSchema(intl),
    office: officeSchema(intl),
    city: citySchema(intl),
    mainOfficeCity: mainOfficeCitySchema(intl),
    mainOfficeNumber: mainOfficeNumberSchema(intl),
    reserveOfficeCity: reserveOfficeCitySchema(intl),
    reserveOfficeNumber: reserveOfficeNumberSchema(intl),
  }),
]

export {receiverOverviewSchema, receiverContactsSchema, addReceiverSchema}
