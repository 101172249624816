import React from 'react'
import {Formik, Form, FormikValues} from 'formik'
import {handleFileUpload} from '../../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {useState} from 'react'
import {receiverOverviewSchema} from '../../../receivers-list/core/yup/receiver-page'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {FileInput} from '../../../../../../modules/custom/form-elements/file-inputs/FileInput'
import {ISetFieldValue} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {NewReceiverFullForm, Receiver} from '../../../receivers-list/core/_models'
import {uploadFile, updateReceiver} from '../../../receivers-list/core/_requests'
import {PatronymicCheckbox} from '../../../elements/PatronymicCheckbox'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {ReceiverType} from '../../../elements/ReceiverType'
import {CheckboxSwitcher} from '../../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {useLayout} from "../../../../../../../_metronic/layout/core";

interface Props {
  receiver: Receiver
  setCurrReceiver: (obj: Receiver) => void
}

export function ReceiverOverview({receiver, setCurrReceiver}: Props) {
  const intl = useIntl()
  const {websiteData} = useLayout()
  const initialValues = {
    active: receiver.enabled === true ? 1 : 0,
    firstName: receiver.firstName || '',
    lastName: receiver.lastName || '',
    patronymic: receiver.patronymic || '',
    noPatronymic: receiver.noPatronymic,
    document: receiver.documentUrl,
    receiverType: [
      receiver?.international === true ? 1 : 0,
      receiver?.final === true ? 2 : 0,
      receiver?.withinCountry === true ? 3 : 0,
    ].filter((item) => +item !== 0),
    main: receiver.main,
  }

  const [docIsLoading, setDocIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [documentObj, setDocumentObj] = useState({
    document: receiver.document,
    documentUrl: receiver.documentUrl,
  })

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setLoading(true)
    setDocIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'document',
      'document',
      uploadFile,
      false,
      'documentUrl'
    )

    setDocumentObj(linkResponse)

    // const response = await updateReceiver(
    //   {
    //     document: linkResponse.document,
    //     documentUrl: linkResponse.documentUrl,
    //   },
    //   receiver.id
    // )

    // console.log('recovfulf res', response)

    // if (response) {
    //   setCurrReceiver(response)
    //   setSuccess(true)

    //   setTimeout(() => {
    //     setSuccess(false)
    //   }, 3000)
    // } else {
    //   // set error
    // }

    setDocIsLoading(false)
    setLoading(false)
  }

  const deleteDocument = async (setFieldValue: ISetFieldValue) => {
    // setLoading(true)

    setFieldValue('document', '')

    setDocumentObj({
      document: null,
      documentUrl: null,
    })

    // const response = await updateReceiver(docNull, receiver.id)

    // if (response) {
    //   setCurrReceiver(response)
    //   setSuccess(true)

    //   setTimeout(() => {
    //     setSuccess(false)
    //   }, 3000)
    // }
    // setLoading(false)
  }

  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: Partial<NewReceiverFullForm>, actions: FormikValues) => {
    console.log('values', values)
    const {receiverType, noPatronymic, firstName, lastName, patronymic, main} = values
    setLoading(true)
    const enabledChanged = values.active !== initialValues.active

    const payload: Partial<Receiver> = {
      firstName,
      lastName,
      patronymic,
      international: receiverType?.includes(1) ? true : false,
      final: receiverType?.includes(2) ? true : false,
      withinCountry: receiverType?.includes(3) ? true : false,
      noPatronymic: noPatronymic || false,
      main,
      document: documentObj.document,
      documentUrl: documentObj.documentUrl,
    }

    if (enabledChanged) {
      payload.enabled = values.active === 1 ? true : false
    }

    const response = await updateReceiver(payload, receiver.id)

    console.log('res', response)

    if (response) {
      setCurrReceiver(response)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <>
      {receiver ? (
        <div className='card mb-5 mb-xl-10'>
          <Formik
            validationSchema={receiverOverviewSchema(intl, websiteData.userNameScript)}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({values, setFieldValue, errors}) => (
              <Form noValidate className='form'>
                {/* {JSON.stringify(values)} */}
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Статус</label>
                    <div className='col-lg-8'>
                      <div className='row flex-nowrap'>
                        <div className='col-lg-6 fv-row me-4'>
                          <CheckboxSwitcher
                            inputName='active'
                            firstOption={intl.formatMessage({id: 'GENERAL.STATUSES.BLOCKED'})}
                            secondOption={intl.formatMessage({id: 'GENERAL.STATUSES.ACTIVE'})}
                            // @ts-ignore
                            value={+values.active}
                            setFieldValue={setFieldValue}
                            bgColor={{first: 'var(--kt-success)', second: 'var(--kt-danger)'}}
                          />
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    <div className='col-lg-8'>
                      <div className='d-flex'>
                        {' '}
                        <InputTemplate
                          inputName='main'
                          type='checkbox'
                          checkboxProps={{
                            checked: values.main || false,
                            disabled: false,
                            setFieldValue: setFieldValue,
                            value: 1,
                            inputName: 'main',
                            type: 'checkbox',
                          }}
                          title={intl.formatMessage({id: 'RECEIVERS.REC_PAGE.MAIN_LABEL'})}
                          containerMarginBottom={false}
                          promptText={'RECEIVERS.REC_PAGE.MAIN_PROMPT'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      <FormattedMessage id='RECEIVERS.REC_PAGE.FULL_NAME_LABEL' />
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <InputTemplate
                            addPrompt={false}
                            inputName='firstName'
                            required={false}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'RECEIVERS.REC_PAGE.NAME_PLACEHOLDER',
                            })}
                            addSymbol={
                              <KTSVG
                                path='/media/icons/duotune/communication/com006.svg'
                                className='svg-icon-3'
                              />
                            }
                            symbolMarginTop={false}
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <InputTemplate
                            addPrompt={false}
                            inputName='lastName'
                            required={false}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'RECEIVERS.REC_PAGE.LAST_NAME_PLACEHOLDER',
                            })}
                            addSymbol={
                              <KTSVG
                                path='/media/icons/duotune/communication/com006.svg'
                                className='svg-icon-3'
                              />
                            }
                            symbolMarginTop={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>{' '}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <InputTemplate
                            addPrompt={false}
                            inputName='patronymic'
                            required={false}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'RECEIVERS.REC_PAGE.PATRONYMIC_PLACEHOLDER',
                            })}
                            addSymbol={
                              <KTSVG
                                path='/media/icons/duotune/communication/com006.svg'
                                className='svg-icon-3'
                              />
                            }
                            symbolMarginTop={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        {' '}
                        <PatronymicCheckbox
                          setFieldValue={setFieldValue}
                          title={intl.formatMessage({
                            id: 'RECEIVERS.ADD_REC_MODAL.NO_PATRONYMIC_CHECKBOX',
                          })}
                          value={values.noPatronymic || false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='d-flex col-lg-4 col-form-label fw-bold fs-6 position-relative required'>
                      <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE' />
                    </label>
                    <div className='col-lg-8'>
                      <ReceiverType values={values} />
                    </div>
                  </div>
                  <FileInput
                    title={intl.formatMessage({
                      id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                    })}
                    inputName='document'
                    inputId={`${receiver.id}-receiver-file-input`}
                    uploadedFile={{
                      base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`,
                      link: documentObj.documentUrl || '',
                    }}
                    setFieldValue={setFieldValue}
                    uploadFunction={saveImage}
                    deleteFunction={deleteDocument}
                    placeholder={intl.formatMessage({
                      id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                    })}
                    notification={{
                      bool: true,
                      text: intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE.PROMPT',
                      }),
                      borderTop: false,
                    }}
                    loading={docIsLoading}
                    required={
                      values?.receiverType?.includes(1) || values?.receiverType?.includes(2)
                    }
                  />
                </div>
                <CardFooter loading={loading} success={success} />
              </Form>
            )}
          </Formik>{' '}
        </div>
      ) : (
        <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
      )}
    </>
  )
}
