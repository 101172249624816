/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, ErrorMessage, useFormikContext} from 'formik'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {PhoneInput} from "../../../../../modules/custom/form-elements/PhoneInput";

type TProps = {
  inputName?: string
  value?: string
}
type DynamicValues = Partial<ICrauzerUser> & {[key: string]: any}

function ProfileNumberItem({inputName = 'phoneNumber', value}: TProps) {
  const {setFieldValue} = useFormikContext<DynamicValues>()

  return (
    <div className='position-relative p-0'>
      <Field name={inputName}>
        {() => (
          <PhoneInput setFieldValue={setFieldValue} inputName={inputName} value={value}/>
        )}
      </Field>

      <div className='text-danger'>
        <ErrorMessage name={inputName} />
      </div>
    </div>
  )
}

export {ProfileNumberItem}
