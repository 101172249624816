import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../helpers'
import {establishOwnerFromDomain} from "../helpers/custom/funcs/establishOwnerFromDomain";
import {getLocalesData} from "../../app/modules/general/core/_requests";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: string
  setLanguage: (lang: string) => void
  locales: string[]
}

const initialState = {
  selectedLang: 'uk',
  setLanguage: () => {},
  locales: ['uk', 'ru'],
}

function langIsSet() {
  return !!localStorage.getItem(I18N_CONFIG_KEY)
}

export function getConfig():Pick<Props, 'selectedLang'> {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return {selectedLang: initialState.selectedLang}
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}
const useI18n = () => {
  return useContext(I18nContext)
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
  const {selectedLang} = getConfig()
  const [locale, setLocale] = useState<string>(selectedLang)
  const [locales, setLocales] = useState<string[]>(initialState.locales)

  const setLanguage = (lang:string) => {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
    setLocale(lang)
    console.log('ls', lang)
  }

  useEffect(() => {
    let owner = establishOwnerFromDomain()
    getLocalesData(owner)
      .then(({data}) => {
        if (!langIsSet()) {
          setLanguage(data.default)
        }
        setLocales(data.locales)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])
  return <I18nContext.Provider value={{selectedLang: locale, setLanguage, locales}}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang, useI18n}
